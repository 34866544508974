import { Box, Button, Grid } from '@material-ui/core';
import React, { Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Toaster from '../components/alerts/Toaster';
import useCurrentTask from '../components/bpm/useCurrentTask';
import { default as Loader, default as Loading } from '../components/loader/Loader';
import DateUserInput from '../components/userInputs/DateUserInput';
import loanRequestService from '../services/loanRequest.service';
import './../styles/components/provinceSelection.scss';
import MacroPage from './shared/MacroPage';

export const CustomerAfipData = (props) => {
    const { hashKey } = props.match.params;
    const { taskDefinitionKey } = props;
    const [loanRequest, setLoanRequest] = useState(null);
    const [validForm, setValidForm] = useState(false);
    const [birthDate, setBirthDate] = useState(null);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(null);
    const [message, setMessage] = useState(null);
    const task = useCurrentTask(hashKey, taskDefinitionKey);
    const { t } = useTranslation();
    const history = useHistory();

    useEffect(() => {
        async function getLoanRequest() {
            try {
                setLoanRequest((await loanRequestService.getLoanRequest(hashKey)).data);
            } catch (error) {
                setLoading(false);
                if (error.response.data) {
                    setMessage(t(`WORKFLOW_ERROR.${error.response.data.cause}.DESCRIPTION`));
                    setOpen(true);
                }
            }
        }

        getLoanRequest();
    }, [hashKey]);

    const handleNextPressed = async (event) => {
        event.preventDefault();
        try {
            setLoading(true);
            await loanRequestService.setCustomerAfipData(hashKey, birthDate);
            await task.completeTask();
            setLoading(false);
        } catch (error) {
            setLoading(false);
            if (error.response.data) {
                setMessage(t(`WORKFLOW_ERROR.${error.response.data.cause}.DESCRIPTION`));
                setOpen(true);
            } else {
                setMessage(t(`APPLICATION_ERROR.${error.response.status}.DESCRIPTION`));
                setOpen(true);
            }
        }
    };

    const ShowToaster = (props) => {
        return <Toaster elevation={6} variant="filled" {...props} />;
    };

    const hasToDisable = (dateValue, initialValue) => {
        return false;
    };

    const handleBirthDateChange = (userInput, birthDateValue) => {
        setBirthDate(birthDateValue);
        setValidForm(birthDateValue);
    };

    const handleToasterClose = () => {
        setOpen(false);
    };

    return (
        <MacroPage hideWhatsAppComponent creditId={loanRequest?.sappCreditId}>
            <Loader loading={loading} />
            <form onSubmit={handleNextPressed}>
                <Grid container xs={12} className="mt-4 mt-0-res">
                    <Grid item xs={0} md={1}></Grid>
                    <Grid item xs={12} md={10}>
                        <p className="fs-16 fw-400 mb-1">Sacalo ahora y empezá a disfrutar</p>
                        <h3 className=" fw-500 fs-24 color-primary mt-1">Tu Préstamo Personal 100% online</h3>
                        <div className="mt-2"></div>

                        <p className=" fw-500 fs-26 color-primary ">Nos faltaron algunos datos para poder continuar</p>
                        <div className="mt-1"></div>

                        <Grid container direction="column" justifyContent="center" alignItems="left" lg={12} sm={12} xs={12}>
                            {loanRequest && !loanRequest.birthDate && (
                                <Suspense fallback={<Loading />}>
                                    <div className="mt-2">
                                        <DateUserInput
                                            stepName="completeCustomerAfipData"
                                            userInput={{ name: 'PtllaFechaNacimiento', userInputOptions: { label: 'Fecha Nacimiento' } }}
                                            onChange={handleBirthDateChange}
                                            onDisable={hasToDisable}
                                        />
                                    </div>
                                </Suspense>
                            )}
                        </Grid>

                        <Grid container direction="row" justifyContent="center" alignItems="center" xs={12}>
                            <Grid item xs={12}>
                                <Box className="mt-5"></Box>
                            </Grid>

                            <Grid item xs={12} sm={5} md={6} lg={4}>
                                <div className="startButton center">
                                    <Button
                                        className="width-100-per width-80-res"
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        disabled={!validForm}>
                                        Siguiente
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>

                        <Grid item>
                            <ShowToaster open={open} textToShow={message} type="error" handleToasterClose={handleToasterClose} />
                        </Grid>
                    </Grid>
                    <Grid item xs={0} md={1}></Grid>
                </Grid>
            </form>
        </MacroPage>
    );
};
