import { Box, Button, Grid, makeStyles } from '@material-ui/core';
import * as qs from 'qs';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import selfieImg from '../assets/selfie_vertical.svg';
import Toaster from '../components/alerts/Toaster';
import useCurrentTask from '../components/bpm/useCurrentTask';
import ContinueMobile from '../components/deviceCommunication/continueMobile';
import { getEnv, URL_4ID } from '../env';
import useLoanRequest from '../hooks/useLoanRequest';
import documentService from '../services/document.service';
import eventTrackingService from '../services/eventtracking.service';
import '../styles/components/documentsUpload.scss';
import Loader from './../components/loader/Loader';
import DocumentsUploadHints from './shared/DocumentsUploadHints';
import MacroStepPage from './shared/MacroStepPage';

const useStyles = makeStyles((theme) => ({
    captureStyle: {
        width: '100%',
        height: 'auto',
        maxHeight: 'auto',
        borderRadius: '8px',
        marginLeft: '30px',
    },
    captureStylePortrait: {
        width: '100%',
        height: 'auto',
        borderRadius: '8px',
        [theme.breakpoints.down('sm')]: {
            width: 'auto',
            maxHeight: '250px',
            maxWidth: '100%',
        },
    },
}));

export const InitSelfie4idPage = (props) => {
    const { hashKey } = props.match.params;
    const { taskDefinitionKey } = props;
    const [errorCode, setErrorCode] = useState(null);
    const analyticsStep = 8;
    const [loading, setLoading] = useState(null);
    const [xsValue, setXsValue] = useState(12);
    const classes = useStyles();
    const [message, setMessage] = useState('Se produjo un error');
    const { t } = useTranslation();
    const { loanRequest, loadingLoanRequest } = useLoanRequest(hashKey);
    const task = useCurrentTask(hashKey, taskDefinitionKey);
    const [secKeyParam, setSecKeyParam] = useState(null);
    const [externalTrxId, setExternalTrxId] = useState(null);
    const submitFormRef = useRef(null);
    const [open, setOpen] = useState(false);
    const formRef = useRef(null);
    const [decision, setDecision] = useState(false);
    const url4Id = getEnv(URL_4ID);
    const [taskLoaded, setTaskLoaded] = useState(false);

    const handleToasterClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (task && !taskLoaded) {
            setTaskLoaded(true);
        }
    }, [task]);

    useEffect(() => {
        if (loanRequest && taskLoaded && decision) {
            handleNext();
        }
    }, [decision, taskLoaded, loanRequest]);

    useEffect(() => {
        if (secKeyParam && externalTrxId) {
            eventTrackingService.trackEvent('4id_requested', 'sin qr', null, analyticsStep);
            submitForm();
        }
    }, [secKeyParam, externalTrxId]);

    const handleNext = async () => {
        try {
            setLoading(true);
            eventTrackingService.trackEvent('4id_completed', '', null, analyticsStep);
            await task.completeTask();
            eventTrackingService.trackEvent(
                'selfie completed',
                loanRequest.documentUploadAccessMethod === 'qr' ? 'con qr' : 'sin qr',
                null,
                analyticsStep
            );
        } catch (error) {
            let message = 'Algo salió mal al verificar tu identidad. Por favor volvé a intentar en unos segundos.';
            if (error.response && error.response.data && error.response.data.errorCode) {
                setErrorCode(error.response.data.errorCode);
                message = t(`RENAPER_VALIDATION_ERROR.${errorCode}.DESCRIPTION`);
            }
            setMessage(message);
            setOpen(true);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        async function updateValidationResult(hashKey, step, decision, externaltrxid) {
            try {
                setLoading(true);
                await documentService.updateValidationResult(hashKey, secKeyParam, step, decision, externaltrxid);
                if (decision === 'HIT') {
                    setDecision(true);
                } else {
                    setMessage(t('CAPTURE_ERRORS.4ID.VALIDATION_FAIL'));
                    setOpen(true);
                }
            } catch (error) {
                setMessage(t('CAPTURE_ERRORS.4ID.GENERAL'));
                setOpen(true);
            } finally {
                setLoading(false);
            }
        }

        const { idtx, step, decision, externaltrxid } = qs.parse(props.location.search, { ignoreQueryPrefix: true });
        if (idtx) {
            updateValidationResult(hashKey, step, decision, idtx);
        }
        if (step) {
            switch (step) {
                case '223':
                    break;
                case '227':
                case '445':
                    setMessage(t(`CAPTURE_ERRORS.4ID.${step}`));
                    setOpen(true);
                    break;
                default:
                    setMessage(t(`CAPTURE_ERRORS.4ID.UNKNOWN`));
                    setOpen(true);
                    break;
            }
        }
    }, []);

    const ShowToaster = (props) => {
        return <Toaster elevation={6} variant="filled" {...props} />;
    };

    const handleGo4id = async () => {
        if (loanRequest && taskLoaded && decision) {
            handleNext();
            return;
        }
        let responseUrl = '/' + props.taskDefinitionKey + '/' + hashKey;
        try {
            setLoading(true);
            let secKey = await documentService.start4idValidation(hashKey, responseUrl);
            setSecKeyParam(secKey.data.sessionId);
            setExternalTrxId(secKey.data.externalTrxId);
        } catch (error) {
            if (error.response && error.response.data && error.response.data.errorCode) {
                setErrorCode(error.response.data.errorCode);
                setMessage(t(`CAPTURE_ERRORS.4ID.${error.response.data.errorCode}`));
            } else {
                setMessage(t('CAPTURE_ERRORS.4ID.GENERAL'));
            }
            setOpen(true);
        } finally {
            setLoading(false);
        }
    };

    const hintSelfie = useMemo(() => DocumentsUploadHints(true), []);

    const submitForm = () => {
        submitFormRef.current.click();
    };

    return (
        <MacroStepPage
            headerLead="Estás muy cerca de tu préstamo"
            headerTitle="Validá tu identidad"
            hashKey={hashKey}
            task={task}
            backToText="DNI Dorso"
            taskDefinitionKey={props.taskDefinitionKey}
            hideStepper={true}
            stepNumber={1}
            totalSteps={4}
            className="mt-1"
            creditId={loanRequest?.sappCreditId}>
            {isMobile ? (
                loanRequest ? (
                    <>
                        <Loader loading={loading || loadingLoanRequest} />
                        <Grid item container style={{ zIndex: 100 }} xs={12} direction="row" justifyContent="center">
                            <Grid item container xs={12} alignItems="center">
                                <p className="fs-20 fw-500 color-primary">Prueba de vida </p>
                                <div className="mt-1"></div>
                            </Grid>

                            <Box m={2} />
                            <Grid item xs={12} lg={4} className="mt-4">
                                <div className="center">
                                    <img src={selfieImg} id="oldDni" alt="" width="100px" />
                                </div>
                            </Grid>
                            <Box m={1} />
                            <Grid item container xs={xsValue} className="mb-2">
                                {hintSelfie}
                            </Grid>
                            <Box m={1} />
                            <Grid item xs={12}>
                                <div className="center mt-2">
                                    <Button
                                        onClick={handleGo4id}
                                        className="mt-4 width-100-per"
                                        variant="contained"
                                        color="primary"
                                        disabled={loading}>
                                        Siguiente
                                    </Button>
                                </div>
                            </Grid>
                            {secKeyParam && (
                                <Grid item xs={12} sm={12} md={12} lg={12} className="">
                                    <form id="idForm" ref={formRef} action={url4Id} method="post" className="width-100-per">
                                        <input type="hidden" name="idapp" value="bmacro_app" />
                                        <input type="hidden" name="seckey" value={secKeyParam} />
                                        <input type="hidden" name="country" value="ARG" />
                                        <input type="hidden" name="idmaqc_service" value="ONBOARDING_DIGITALPLATFORM" />
                                        <input type="hidden" name="profile_services" value="ONBOARDING_DIGITALPLATFORM" />
                                        <input type="hidden" name="services" value="ONBOARDING_DIGITALPLATFORM" />
                                        <input type="hidden" name="dni" value={loanRequest.customerId} />
                                        <input type="hidden" name="sexo" value={loanRequest.sex} />
                                        <input type="hidden" name="externaltxid" value={externalTrxId} />
                                        <Button ref={submitFormRef} type="submit" style={{ display: 'none' }}></Button>
                                    </form>
                                </Grid>
                            )}
                        </Grid>
                        {open && (
                            <div className="mt-4">
                                <ShowToaster open={open} textToShow={message} type="error" handleToasterClose={handleToasterClose} />
                            </div>
                        )}
                    </>
                ) : null
            ) : (
                <ContinueMobile hashKey={hashKey} />
            )}
        </MacroStepPage>
    );
};
