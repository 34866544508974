import React, { useState } from 'react';
import { FormControl, FormHelperText, InputLabel, Select } from '@material-ui/core';
import '../../styles/components/customSelect.scss';

export const CustomSelect = ({ children, required, fullWidth, label, onChange, onInvalid, helperText, errorHelperText, ...props }) => {
    const [error, setError] = useState(false);

    const handleInvalid = (event) => {
        event.preventDefault();
        if (onInvalid) {
            onInvalid(event);
        }
        setError(true);
    };

    const handleChange = (...args) => {
        if (onChange) {
            onChange(...args);
        }
        setError(false);
    };

    return (
        <FormControl 
            fullWidth={fullWidth} 
            required={required}
            error={error}
        >
            <InputLabel>{label}</InputLabel>

            <Select
                onInvalid={handleInvalid}
                onChange={handleChange}
                error={error}
                {...props}
            >
                {children}
            </Select>
            <FormHelperText>{error ? errorHelperText : helperText}</FormHelperText>
        </FormControl>
    );
};
