import { Box, Button, Grid, makeStyles, Modal } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import Toaster from '../components/alerts/Toaster';
import useCurrentTask from '../components/bpm/useCurrentTask';
import ContinueMobile from '../components/deviceCommunication/continueMobile';
import CustomerDataUserInputs from '../components/userInputs/CustomerDataUserInputs';
import eventTrackingService from '../services/eventtracking.service';
import loanRequestService from '../services/loanRequest.service';
import './../styles/components/customerDataInput.scss';
import MacroStepPage from './shared/MacroStepPage';

export const AffidavitDataInput = (props) => {
    const { hashKey } = props.match.params;
    const { taskDefinitionKey } = props;
    const [loanRequest, setLoanRequest] = useState(null);
    const [validForm, setValidForm] = useState(false);
    const [userInputs, setUserInputs] = useState({});
    const [open, setOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [hasAccount, setHasAccount] = useState(false);
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const task = useCurrentTask(hashKey, taskDefinitionKey);
    const { t } = useTranslation();
    const [modalStyle] = React.useState(getModalStyle);

    useEffect(() => {
        eventTrackingService.trackContentViewEvent('/prestamos-personales/declaracion-jurada');
    }, []);

    function getModalStyle() {
        const top = 50;
        const left = 50;

        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
        };
    }

    const useStylesModal = makeStyles((theme) => ({
        paper: {
            position: 'absolute',
            width: 300,
            backgroundColor: theme.palette.background.paper,

            padding: theme.spacing(2, 2, 3),
        },
    }));

    const useStylesModalText = makeStyles(() => ({
        modalText: {
            fontSize: '11px',
            color: '#000000',
            textAlign: 'justify',

            fontWeight: 'bold',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 1.5,
            letterSpacing: 'normal',
        },
    }));

    const classesModalText = useStylesModalText();
    const classesModal = useStylesModal();

    useEffect(() => {
        async function getLoanRequest() {
            try {
                setLoanRequest((await loanRequestService.getLoanRequest(hashKey)).data);

                loanRequestService
                    .hasAnyAccount(hashKey)
                    .then((response) => {
                        setHasAccount(response.data);
                    })
                    .catch((error) => {
                        console.error(error.response);
                    });
            } catch (error) {
                console.error(error.response);
            }
        }

        getLoanRequest();
    }, [hashKey]);

    useEffect(() => {
        setValidForm(true);
    }, [userInputs]);

    const validateAffidavit = () => {
        let msg = [];
        if (userInputs.PtllaPEP === 'true') {
            msg.push('Alcanzado dentro de la nómina de Personas Expuestas Políticamente');
        }
        if (userInputs.PtllaOCDE === 'true') {
            msg.push('OCDE (CRS)');
        }

        if (userInputs.PtllaSujetoObligadoUIF === 'true') {
            msg.push('Sujeto Obligado ante la UIF');
        }

        if (userInputs.PtllaUSCitizenFATCA === 'true') {
            msg.push('US Person - FATCA');
        }

        setMessages(msg);
        return msg.length === 0;
    };

    const handleNextPressed = (event) => {
        event.preventDefault();

        if (!validateAffidavit()) {
            setOpenModal(true);
            return;
        }

        handleConfirmAffidavit();
    };

    const handleConfirmAffidavit = async () => {
        try {
            await loanRequestService.setCustomerAffidavitData(hashKey, userInputs);

            task.completeTask();
        } catch (error) {
            if (error.response && error.response.data) {
                const { properties, errorCode } = error.response.data;
                const { fieldsToCompleted } = properties;
                if (errorCode === 'ALL_FIELDS_REQUIERED_MUST_BE_COMPLETED') {
                    var msg = t(`WORKFLOW_ERROR.${errorCode}.DESCRIPTION`);
                    fieldsToCompleted.map((field) => (msg += ' ' + t(`FIELDS.${field}`)));
                    setMessage(msg);
                    setOpen(true);
                }
            } else {
                console.error(error);
            }
        }
    };

    const handleComplete = (userInputFilled) => {
        setUserInputs(userInputFilled);
    };

    const ShowToaster = (props) => {
        return <Toaster elevation={6} variant="filled" {...props} />;
    };

    const handleToasterClose = () => {
        setOpen(false);
    };

    const handleContinue = () => {
        setOpenModal(false);
        handleConfirmAffidavit();
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const body = (
        <div style={modalStyle} className={classesModal.paper}>
            <p className="fs-15 color-dark-gray fw-500">Seleccionaste que estás alcanzado por las regulaciones</p>
            <ul>
                {messages.map((message, index) => (
                    <li key={index} className={classesModalText.modaltext} id="simple-modal-description">
                        {message}
                    </li>
                ))}
            </ul>
            <p className="fs-15 color-dark-gray fw-500 mt-2">¿Confirmás tu selección?</p>

            <div className="mt-2" style={{ display: 'flex', justifyContent: 'center', gap: '20px' }}>
                <Button onClick={handleCloseModal} className="" color="primary">
                    Cancelar
                </Button>
                <Button onClick={handleContinue} className="" variant="contained" color="primary">
                    Confirmar
                </Button>
            </div>
        </div>
    );

    return (
        <MacroStepPage
            headerLead="Ya elegiste tu préstamo y cuotas"
            headerTitle="Completá tus datos"
            hashKey={hashKey}
            task={task}
            taskDefinitionKey={taskDefinitionKey}
            backToText={isMobile ? 'Selección de Oferta' : undefined}
            hideStepper={true}
            stepNumber={3}
            totalSteps={4}
            creditId={loanRequest?.sappCreditId}>
            {!isMobile ? (
                <ContinueMobile hashKey={hashKey} />
            ) : (
                <>
                    <form onSubmit={handleNextPressed}>
                        <Grid item container className="mb-40-res">
                            {loanRequest && (
                                <CustomerDataUserInputs stepName={taskDefinitionKey} hashKey={loanRequest.hashKey} onComplete={handleComplete} />
                            )}
                        </Grid>

                        <Grid item container direction="row" justifyContent="center" alignItems="center" xs={12}>
                            <Grid item xs={12}>
                                <Box className="mt-5"></Box>
                            </Grid>

                            <Grid item xs={12} sm={5} md={6} lg={4}>
                                <div className="mb-footer startButton center mb-20-web mt-20-web">
                                    <Button
                                        className="width-100-per width-80-res"
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        disabled={!validForm}>
                                        Siguiente
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                        <ShowToaster open={open} textToShow={message} type="error" handleToasterClose={handleToasterClose} />
                        <Modal
                            open={openModal}
                            onClose={handleCloseModal}
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description">
                            {body}
                        </Modal>
                    </form>
                </>
            )}
        </MacroStepPage>
    );
};
