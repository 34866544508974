import { Box, Button, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import useCurrentTask from '../components/bpm/useCurrentTask';
import UserInputs from '../components/userInputs/UserInputs';
import loanRequestService from '../services/loanRequest.service';
import './../styles/components/provinceSelection.scss';
import MacroPage from './shared/MacroPage';

export const ProvinceSelection = (props) => {
    const { hashKey } = props.match.params;
    const { taskDefinitionKey } = props;
    const [loanRequest, setLoanRequest] = useState(null);
    const [validForm, setValidForm] = useState(false);
    const [userInputs, setUserInputs] = useState({});

    const task = useCurrentTask(hashKey, taskDefinitionKey);

    useEffect(() => {
        async function getLoanRequest() {
            try {
                setLoanRequest((await loanRequestService.getLoanRequest(hashKey)).data);
            } catch (error) {
                console.error(error.response);
            }
        }

        getLoanRequest();
    }, [hashKey]);

    useEffect(() => {
        if (userInputs) {
            setValidForm(true);
        }
    }, [userInputs]);

    const handleNextPressed = async (event) => {
        event.preventDefault();
        try {
            await loanRequestService.setProvinceCode(hashKey, loanRequest.branchProvinceCode);

            task.completeTask();
        } catch (error) {
            console.error(error);
        }
    };

    const handleComplete = (userInputFilled) => {
        if (userInputFilled.PtllaProvincia) {
            setLoanRequest({
                ...loanRequest,
                branchProvinceCode: userInputFilled.PtllaProvincia,
            });
            setUserInputs(userInputFilled);
        }
    };

    return (
        <MacroPage creditId={loanRequest?.sappCreditId}>
            <form onSubmit={handleNextPressed}>
                <Grid container direction="row" justifyContent="center" alignItems="center" lg={12} sm={12} xs={12}>
                    <p className=" mt-3 fw-200 d-h3">Nos dirías en qué provincia radicarás tu préstamo?</p>
                    <div className="mt-3"></div>

                    <Grid container direction="column" justifyContent="center" alignItems="left" lg={10} sm={12} xs={12}>
                        {loanRequest && <UserInputs stepName={taskDefinitionKey} hashKey={loanRequest.hashKey} onComplete={handleComplete} />}
                    </Grid>

                    <Grid container direction="row" justifyContent="center" alignItems="center" xs={12}>
                        <Grid item xs={12}>
                            <Box className="mt-5"></Box>
                        </Grid>

                        <Grid item xs={12} sm={5} md={6} lg={4}>
                            <Button className="width-100-per" type="submit" variant="contained" color="primary" disabled={!validForm}>
                                Siguiente
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </MacroPage>
    );
};
